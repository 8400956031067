.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.loginInput {
  margin: 16px 0;

  width: 100%;
}

.idPasswordFind {
  display: inline-block;

  margin: 8px 0;

  width: 100%;

  text-align: center;
}

.idPasswordFind a {
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--white);
}

.loginButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;

  width: 100%;
}

.loginButton {
  background-color: var(--yellow);

  width: 50%;

  color: var(--white);
}

.loginErrorMessage {
  position: relative;
  top: 8px;

  font-size: var(--font-size-small);
  color: var(--red);
}

.loginFormContainer {
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 25dvh;

  background-color: var(--blue-primary);

  border-radius: 32px 32px 32px 32px;

  min-height: 3rem;

  color: var(--white);

  z-index: 1;
}

.loginContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  white-space: nowrap;
}

.loginFormHeader {
  font-size: var(--font-size-x-large);
}
