.classDetailContainer {
  position: fixed;
  top: 0;

  background-color: var(--blue-primary);

  border-radius: 32px 32px 0 0;

  width: 100%;
  height: 150%;

  z-index: 11;

  user-select: none;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 24px;

  cursor: pointer;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;

  width: 100%;
}

.groupHeaderContainer {
  width: 100%;

  text-align: center;
}

.groupTitle {
  text-shadow: 0 4px 4px #00000040;

  font-size: var(--font-size-large);
  color: var(--white);
}

.groupTime {
  text-shadow: 0 4px 4px #00000040;

  font-weight: 600;
  color: var(--white);
}

.line {
  margin: 8px 0;

  border-top: 1px solid var(--white);

  width: 100%;
}

.groupKeywordContainer {
  margin: 8px 0;

  width: 100%;

  padding: 0 16px;
}

.groupKeywordHeader,
.groupActivityHeader,
.groupRecommendHeader {
  font-weight: 600;
  color: var(--yellow);
}

.groupKeywords {
  display: flex;
  gap: 16px;

  margin-top: 8px;
}

.groupKeyword {
  display: inline;

  background-color: var(--white);

  border-radius: 9999px;

  padding: 12px;
}

.groupActivityContainer {
  margin: 16px 0;

  border: 1px solid var(--white);
  border-radius: 16px;

  padding: 12px 16px;

  width: 100%;
}

.groupActivityGaugeContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 8px;
  margin-bottom: 8px;
}

.groupActivityGaugeLegend {
  color: white;
}

.groupActivityGauge {
  flex-grow: 1;

  display: flex;

  border-radius: 9999px;
  background-color: var(--white);

  overflow: hidden;
}

.groupActivityGaugePart {
  padding: 2px 0;

  font-weight: 600;
  font-size: var(--font-size-small);

  text-align: center;
}

.groupActivityGaugePart:last-child {
  background-color: var(--yellow);
}

.groupRecommendContainer {
  margin: 8px 0;

  padding: 0 16px;

  width: 100%;
}

.groupRecommendText {
  margin-top: 8px;

  color: var(--white);
  text-align: left;
  white-space: pre-wrap;
}

.inputSelectionText {
  margin: 8px 0;

  font-weight: 600;
  font-size: var(--font-size-small);
  color: var(--yellow);
}

.inputSelectionButton {
  background-color: var(--yellow);

  box-shadow: 0px 4px 4px 0px #00000040;

  font-weight: 600;
  color: var(--white);
}

.inputSelectionButton:disabled{
  background-color: var(--gray);
}

.fadeOut {
  position: fixed;
  bottom: 0;

  width: 100%;
  height: 100px;

  z-index: 12;
  pointer-events: none;
}
